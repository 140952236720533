export class Gallery {
    constructor(list) {
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "images", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "modal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: document.createElement('dialog')
        });
        this.list = list;
        let i = 0;
        this.list.querySelectorAll('li').forEach((li) => {
            const img = li.querySelector('img');
            if (!!img) {
                this.images.set(i, img);
                img.dataset.galleryIndex = "" + i;
                i++;
            }
        });
        this.modal.close();
        this.modal.classList.add('gallery-modal');
        this.buildModal();
        document.querySelector('body')?.append(this.modal);
        this.images.forEach(img => {
            img.closest('a')?.addEventListener('click', evt => {
                evt.preventDefault();
                this.setData({
                    src: img.src
                });
            });
        });
    }
    buildModal() {
        const grid = document.createElement('div');
        grid.classList.add('wrapper-grid');
        const imageWrapper = document.createElement('div');
        imageWrapper.classList.add('wrapper-image');
        const img = new Image();
        img.addEventListener('click', evt => {
            evt.stopPropagation();
        });
        imageWrapper.appendChild(img);
        const textWrapper = document.createElement('div');
        textWrapper.classList.add('wrapper-text');
        const download = document.createElement('a');
        download.addEventListener('click', evt => {
            evt.stopPropagation();
        });
        download.classList.add('download');
        download.setAttribute('download', '');
        textWrapper.appendChild(download);
        const close = document.createElement('a');
        close.classList.add('close');
        close.addEventListener('click', () => {
            this.modal.close();
        });
        const controls = document.createElement('div');
        controls.classList.add('wrapper-controls');
        const next = document.createElement('a');
        next.classList.add('next');
        next.addEventListener('click', evt => {
            evt.preventDefault();
            evt.stopPropagation();
            this.next();
        });
        const prev = document.createElement('a');
        prev.classList.add('prev');
        prev.addEventListener('click', evt => {
            evt.preventDefault();
            evt.stopPropagation();
            this.prev();
        });
        controls.append(prev, next);
        grid.appendChild(close);
        grid.appendChild(controls);
        grid.appendChild(imageWrapper);
        grid.appendChild(textWrapper);
        this.modal.appendChild(grid);
    }
    setData(img) {
        this.modal.querySelector('img')?.setAttribute('src', img.src);
        this.modal.querySelector('.download')?.setAttribute('href', img.src);
        let filename = img.src;
        let path = filename.split('/');
        if (path.length) {
            filename = path.pop() || "";
        }
        this.modal.querySelector('.download')?.setAttribute('download', filename);
        this.modal.showModal();
    }
    isOpen() {
        return this.modal?.hasAttribute('open');
    }
    next() {
        this.setData({
            src: this.getNext() || ""
        });
    }
    prev() {
        this.setData({
            src: this.getPrev() || ""
        });
    }
    getNext() {
        if (!this.isOpen) {
            return;
        }
        const current = this.getCurrent();
        for (const [key, img] of this.images.entries()) {
            if (img?.src === current) {
                if (this.images.has((key + 1))) {
                    return this.images.get(key + 1)?.src;
                }
            }
        }
        ;
        return this.images.values().next().value?.src;
    }
    getPrev() {
        if (!this.isOpen) {
            return;
        }
        const current = this.getCurrent();
        for (const [key, img] of this.images.entries()) {
            if (img?.src === current) {
                if (this.images.has((key - 1))) {
                    return this.images.get(key - 1)?.src;
                }
            }
        }
        ;
        return Array.from(this.images.values()).pop()?.src;
    }
    getCurrent() {
        const img = this.modal.querySelector('img');
        return img?.src;
    }
}
