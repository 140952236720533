export class ArchiveFilter {
    constructor() {
        document.querySelectorAll('form.archive-filter').forEach(form => {
            form.querySelectorAll('select').forEach(el => {
                el.addEventListener('change', () => {
                    form.submit();
                });
            });
        });
    }
}
