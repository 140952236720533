import { HeaderService } from "./HeaderService";
import { FormService } from "./FormService";
import { Slider } from "./Slider";
import { Scroller } from "./Scroller";
import { Gallery } from "./Gallery";
import { ArchiveFilter } from "./FilterArchive";
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    }
    else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}
docReady(() => {
    const headerService = new HeaderService();
    const formService = new FormService();
    const sliders = [];
    const scrollers = [];
    const galleries = [];
    document.querySelectorAll('.slider').forEach(slider => sliders.push(new Slider(slider)));
    document.querySelectorAll('.scroller').forEach(scroller => scrollers.push(new Scroller(scroller)));
    scrollers.forEach(scroller => {
        if (scroller.scroller.dataset.targetControls && document.getElementById(scroller.scroller.dataset.targetControls)) {
            scroller.controls = document.getElementById(scroller.scroller.dataset.targetControls);
            scroller.setup();
        }
    });
    document.querySelectorAll('.gallery-widget').forEach(gallery => galleries.push(new Gallery(gallery)));
    const archiveFilter = new ArchiveFilter();
});
