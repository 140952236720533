export class Scroller {
    constructor(scroller, controls) {
        Object.defineProperty(this, "scroller", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "controls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.scroller = scroller;
        this.controls = controls;
    }
    setup() {
        const elCount = this.scroller.querySelectorAll('li').length;
        if (elCount > 0 && this.scroller.scrollWidth > this.scroller.clientWidth) {
            this.controls?.querySelector('.next')?.addEventListener('click', evt => {
                evt.preventDefault();
                this.scroller.scroll({ left: (this.scroller.scrollLeft + (this.scroller.scrollWidth / elCount)), behavior: 'smooth' });
            });
            this.controls?.querySelector('.prev')?.addEventListener('click', evt => {
                evt.preventDefault();
                this.scroller.scroll({ left: (this.scroller.scrollLeft - (this.scroller.scrollWidth / elCount)), behavior: 'smooth' });
            });
        }
    }
}
