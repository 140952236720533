export class Slider {
    constructor(slider) {
        Object.defineProperty(this, "slider", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slides", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "controls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "t", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 5
        });
        Object.defineProperty(this, "interval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        console.log('slider init');
        this.slider = slider;
        if (!this.slider) {
            return;
        }
        if (this.slider.dataset.sliderInterval) {
            this.t = +this.slider.dataset.sliderInterval;
        }
        this.slides = this.slider.querySelector('ul.slides').querySelectorAll('li');
        let i = 1;
        this.slides.forEach(slide => {
            slide.dataset.slideIndex = i;
            i++;
        });
        this.goto(1);
        if (this.slider.dataset.sliderAutostart) {
            this.start();
        }
        this.controls = this.slider.querySelector('.slider-controls');
        if (this.controls) {
            this.controls.querySelector('.prev')?.addEventListener('click', (evt) => {
                evt.preventDefault();
                this.stop();
                this.prev();
            });
            this.controls.querySelector('.next')?.addEventListener('click', (evt) => {
                evt.preventDefault();
                this.stop();
                this.next();
            });
        }
    }
    goto(index) {
        this.stop();
        this.slides.forEach(slide => {
            slide.classList.remove('active');
            if (+slide.dataset.slideIndex === index) {
                slide.classList.add('active');
            }
        });
    }
    start() {
        this.interval = window.setInterval(() => {
            this.next();
        }, this.t * 1000);
    }
    stop() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = 0;
        }
    }
    next() {
        for (let i = 0; i < this.slides.length; i++) {
            if (this.slides[i].classList.contains('active')) {
                this.slides[i].classList.remove('active');
                if (i + 1 < this.slides.length) {
                    this.slides[i + 1].classList.add('active');
                }
                else {
                    this.slides[0].classList.add('active');
                }
                break;
            }
        }
    }
    prev() {
        for (let i = this.slides.length - 1; i >= 0; i--) {
            if (this.slides[i].classList.contains('active')) {
                this.slides[i].classList.remove('active');
                if (i - 1 >= 0) {
                    this.slides[i - 1].classList.add('active');
                }
                else {
                    this.slides[this.slides.length - 1].classList.add('active');
                }
                break;
            }
        }
    }
    getCurrentIndex() {
        return this.slides.filter(slide => {
            return slide.classList.has('active');
        })
            .reduce(slide => {
            return +slide.dataset.slideIndex;
        });
    }
}
