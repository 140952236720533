export class HeaderService {
    constructor() {
        document.querySelector('.toggle-nav')?.addEventListener('click', event => {
            event.preventDefault();
            document.querySelector('header')?.classList.toggle('nav-active');
            if (document.querySelector('header')?.classList.contains('nav-active')) {
                document.querySelector('header')?.classList.toggle('search-active', false);
            }
        });
        document.querySelector('.toggle-search')?.addEventListener('click', event => {
            event.preventDefault();
            document.querySelector('header')?.classList.toggle('search-active');
            if (document.querySelector('header')?.classList.contains('search-active')) {
                document.querySelector('header')?.classList.toggle('nav-active', false);
            }
        });
        this.setHeaderOffset();
        document.addEventListener('resize', () => { this.setHeaderOffset(); });
    }
    setHeaderOffset() {
        const height = document.querySelector('header')?.clientHeight + "px";
        document.querySelector('header')?.style.setProperty('--header-offset', height);
    }
}
