export class FormService {
    constructor() {
        Object.defineProperty(this, "forms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ulCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.forms = document.querySelectorAll('form.xhr');
        this.forms.forEach(form => {
            form.addEventListener('submit', evt => {
                evt.preventDefault();
                this.processForm(form);
            });
            form.addEventListener('change', () => {
                this.processForm(form);
            });
            const selectCategory = form.querySelector('select[name=category]');
            if (selectCategory) {
                this.ulCategory = form.querySelector('ul.category-filter');
                if (this.ulCategory !== undefined) {
                    // add events to text links
                    let i = 1;
                    this.ulCategory.querySelectorAll('a').forEach(a => {
                        a.addEventListener('click', evt => {
                            evt.preventDefault();
                            console.log("click!");
                            if (a.dataset.catId) {
                                this.resetActiveStylesFromList(form.querySelector('ul.category-filter'));
                                a.parentElement?.classList.add('active');
                                selectCategory.value = a.dataset.catId;
                                //form.dispatchEvent(new Event('submit'));
                                this.processForm(form);
                            }
                        });
                        if (a.parentElement) {
                            a.parentElement.dataset.scrollIndex = "" + i;
                        }
                        i++;
                    });
                    // add stuff for scroll to snap
                    this.ulCategory.dataset.currentScrollIndex = "1";
                    const ulControls = form.querySelector('div.controls-category-filter');
                    const elCount = this.ulCategory.querySelectorAll('li').length;
                    if (elCount > 0 && this.ulCategory.scrollWidth > this.ulCategory.clientWidth) {
                        ulControls?.querySelector('.next')?.addEventListener('click', evt => {
                            evt.preventDefault();
                            this.ulCategory?.scroll({ left: (this.ulCategory.scrollLeft + (this.ulCategory.scrollWidth / elCount)), behavior: 'smooth' });
                        });
                        ulControls?.querySelector('.prev')?.addEventListener('click', evt => {
                            evt.preventDefault();
                            this.ulCategory?.scroll({ left: (this.ulCategory.scrollLeft - (this.ulCategory.scrollWidth / elCount)), behavior: 'smooth' });
                        });
                        this.ulCategory.querySelectorAll('li').forEach(li => {
                            li.querySelector('a')?.addEventListener('click', (evt) => {
                                this.onClickScroll(evt?.target);
                            });
                        });
                    }
                    window.addEventListener('resize', () => {
                        this.setVisibilityControls(form);
                    });
                    this.setVisibilityControls(form);
                }
            }
        });
    }
    setVisibilityControls(form) {
        console.log('triggered');
        const active = form.querySelector('ul.category-filter').scrollWidth > form.offsetWidth;
        form.querySelector('div.controls-category-filter')?.classList.toggle('active', active);
    }
    resetActiveStylesFromList(ul) {
        ul?.querySelectorAll('li').forEach(li => li.classList.remove('active'));
    }
    async processForm(form) {
        const formData = new FormData(form);
        const url = form.attributes.getNamedItem('action')?.value;
        if (!url) {
            return;
        }
        this.disable(form);
        const response = await this.sendRequest(url, 'post', formData);
        const wrapper = this.getList(response);
        form.querySelector('div.loop-wrapper')?.replaceChildren(wrapper);
        this.enable(form);
    }
    getListItems(response) {
        const temp = document.createElement('div');
        temp.innerHTML = response.trim();
        const elements = temp.querySelectorAll('li');
        return [...elements];
    }
    getList(response) {
        const temp = document.createElement('div');
        temp.innerHTML = response.trim();
        const wrapper = temp.querySelector('div.loop-wrapper');
        return wrapper;
    }
    async sendRequest(url, method, formData) {
        if (!url) {
            return "";
        }
        try {
            const request = new Request(url, {
                method: method,
                body: formData
            });
            const text = await fetch(request)
                .then((response) => response.text())
                .then((text) => text);
            return text;
        }
        catch (error) {
            console.error(error?.message);
        }
        return "";
    }
    disable(form) {
        form.querySelector('fieldset').disabled = true;
    }
    enable(form) {
        form.querySelector('fieldset').disabled = false;
    }
    onClickScroll(el) {
        const li = el.closest('li');
        if (li) {
            const elCount = this.ulCategory?.querySelectorAll('li').length;
            const offset = this.getOffset(li); //li.offsetLeft;//Math.max(parseInt(li.dataset.scrollIndex || "0") - 1, 0) * (this.ulCategory.scrollWidth / elCount);
            console.log("scroll to ", offset);
            this.ulCategory?.scroll({ left: offset, behavior: 'smooth' });
        }
    }
    getOffset(el) {
        const parent = el.closest('ul');
        const evtLi = el.closest('li');
        let offset = 0;
        const liList = this.ulCategory?.querySelectorAll('li');
        if (!liList) {
            return 0;
        }
        for (const li of liList.values()) {
            if (li.isEqualNode(evtLi)) {
                console.log('is equal');
                break;
            }
            offset += li.offsetWidth;
            console.log(offset);
        }
        return offset;
    }
}
